import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { FranchiseProperty, FranchiseRequest, SelectItem } from '@models';
import { BrandService } from '@services/brand.service';
import { DataAddressComponent } from '@sharedcomponents/data-address/data-address.component';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { FormatUtils } from '@utils/format-utils';


@Component({
  selector: 'app-franchise-personal',
  templateUrl: './franchise-personal.component.html',
  styleUrls: ['../../franchise.component.scss'],
})
export class FranchisePersonalComponent implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('formAddress', { static: true }) formAddress: DataAddressComponent;

  @Input() info: FranchiseRequest;

  @Output() infoChange = new EventEmitter<FranchiseRequest>();

  civil: SelectItem[] = [];

  readonly dateMask = AppConstants.Mask.date;
  readonly phoneMask = AppConstants.Mask.phone;
  readonly docMask = AppConstants.Mask.doc;
  readonly emailRegexp = AppConstants.EMAIL_REGEXP;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.civil = this.route.snapshot.data.civilStates;
  }

  isValid(): boolean {
    FormUtil.touchForm(this.form.control);
    return this.formAddress.isValid() && this.form.valid;
  }

  initFormIfLoggedIn() {
    const info: FranchiseRequest = this.route.snapshot.data.personalInfo;

    if (info) {
      this.formAddress.initAddress(info.address);
      this.initFormPersonal(info);

      this.infoChange.emit(info);
      setTimeout(() => this.isValid(), 100);
    }
  }

  initFormPersonal(info: FranchiseRequest) {
    info.birthDate = new Date(info.birthDate).toLocaleDateString();

    info.franchiseInformation = {
      investmentCapital: 0,
      availableProperty: true,
      propertyAddress: { city: {}, publicPlace: { id: 1 } },
    } as FranchiseProperty;

    // Gênero vem como '1' ou '2' do backend
    info.gender = +info.gender === 1 ? 'M' : 'F';

    // Necessário para ativar a validação de min/ max length no input e para compensar os leading
    // zereos que o serviço antigo não envia
    info.cpf = info.cpf.toString();
    info.cpf = FormatUtils.maskNumber(info.cpf, 11, '###.###.###-##');

    if (info.landlinePhone) {
      info.landlinePhone = FormatUtils.maskPhone(info.landlinePhone);
    }
    if (info.cellPhone) {
      info.cellPhone = FormatUtils.maskPhone(info.cellPhone);
    }

    // Trata casos que o id vem do backend como 'NaN' ou como números esdrúxulos
    if (
      isNaN(info.maritalStatus.id) ||
      !this.civil.some((item) => item.id === info.maritalStatus.id)
    ) {
      info.maritalStatus.id = null;
    }
  }
}
